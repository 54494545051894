<template>
  <div style="width:80%; margin:0 auto;">
    <a-tabs :default-active-key="active_key" :tab-position="tabPosition" style="background-color: #ffffff;padding: 20px; height: 100vh">
      <a-tab-pane :key="1" tab="快速注册企业小程序">
        <a-spin :spinning="appletLoading">
          <div style="padding: 10px">
            <a-steps :current="quickly_current" v-if="false">
              <a-step v-for="item in quickly_steps" :key="item.title" :title="item.title" />
            </a-steps>
            <div class="steps-content" style="margin-top: 10px;padding: 10px">
              <div v-if="wechat_verificate === wx_register_status.COMPLETED">
                <template v-if="true">
                  <a-result
                    status="success"
                    title="小程序注册成功"
                  >
                    <!--                    sub-title="注册成功后，去申请开通小程序支付功能，若已开通，则不必去申请"-->
                    <template #extra v-if="false">
                      <a-button key="wechat_pay" type="primary" @click="wechatPay">
                        去申请
                      </a-button>
                    </template>
                  </a-result>
                </template>
                <div v-if="corporationInfo">
                  <a-descriptions style="margin-left: 60px" title="企业信息" bordered>
                    <a-descriptions-item label="企业名" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                      <span>{{ corporationInfo.name }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="企业代码" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                      <span>{{ corporationInfo.code }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="法人微信" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                      <span>{{ corporationInfo.legal_persona_wechat }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="法人姓名" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                      <span>{{ corporationInfo.legal_persona_name }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="第三方联系电话" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                      <span>{{ corporationInfo.component_phone }}</span>
                    </a-descriptions-item>
                  </a-descriptions>
                  <a-descriptions style="margin-left: 60px;margin-top: 30px" title="小程序信息" bordered>
                    <a-descriptions-item label="小程序名称" :span="3">
                      {{ corporationInfo.nickname }}
                    </a-descriptions-item>
                    <a-descriptions-item label="小程序简介">
                      {{ corporationInfo.signature }}
                      <a-button type="link" @click="editSignature(corporationInfo.signature)">
                        修改
                      </a-button>
                    </a-descriptions-item>
                    <a-descriptions-item label="电商平台代码">
                      {{ corporationInfo.ebp_code }}
                      <a-button type="link" @click="upcodedata">
                        修改
                      </a-button>
                    </a-descriptions-item>
                    <a-descriptions-item label="电商平台名称">
                      {{ corporationInfo.ebp_name }}
                      <a-button type="link" @click="upNamedata">
                        修改
                      </a-button>
                    </a-descriptions-item>
                    <a-descriptions-item label="营业执照" v-if="false">
                      <a-avatar
                        v-if="corporationInfo.id_card_copy"
                        :src="corporationInfo.id_card_copy"
                        @click="handleIconUrlPreview(corporationInfo.id_card_copy)"
                        alt="营业执照" />
                    </a-descriptions-item>
                    <a-descriptions-item label="小程序图标" v-if="false">
                      <a-avatar
                        v-if="corporationInfo.icon"
                        :src="corporationInfo.icon"
                        @click="handleIconUrlPreview(corporationInfo.icon)"
                        alt="营业执照" />
                    </a-descriptions-item>
                  </a-descriptions>
                </div>
              </div>
              <div v-if="wechat_verificate === wx_register_status.TOSETAVATOR || wechat_verificate === wx_register_status.AVATORFAILED">
                <a-spin :spinning="appletIconLoading">
                  <a-form :form="applet_icon_form" :layout="formLayout">
                    <a-row>
                      <a-col :span="16">
                        <div v-if="wechat_verificate === wx_register_status.AVATORFAILED" style="margin-left: 60px;margin-top: 20px;margin-bottom: 20px;color: #8b8b8b">
                          <span><span style="font-size: 16px;color: black;font-weight: bold">提示：</span>头像设置失败，请重新上传（图片正方形）</span>
                        </div>
                        <a-form-item label="小程序图标" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                          <div class="clearfix">
                            <a-upload
                              v-decorator="['file',{rules: [{required: true, message: '必填项，请填写信息'}]}]"
                              name="file"
                              accept="image/png, image/jpeg, image/jpg"
                              list-type="picture-card"
                              :file-list="fileAvatarList"
                              :before-upload="beforeUpload"
                              @preview="handlePreview"
                              @change="handleAvatarChangePic"
                            >
                              <div v-if="fileAvatarList.length < 1">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">
                                  上传
                                </div>
                              </div>
                            </a-upload>
                          </div>
                        </a-form-item>
                        <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" style="margin-left: 60px">
                          <a-button type="primary" @click="quickSetAppletIcon">提交</a-button>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                </a-spin>
              </div>
              <div>
                <a-form>
                  <a-row v-if="wechat_verificate === wx_register_status.ADDINGNICKNAME">
                    <a-col :span="16">
                      <a-form-item><span style="font-size: 16px">小程序昵称确认中</span></a-form-item>
                      <a-form-item>
                        <a-button type="primary" @click="wechatVerificated">刷新</a-button>
                        <p style="margin-left: 0px;color: #8b8b8b ">若小程序昵称已验证通过，点击“刷新”按钮</p>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
              <div v-if="wechat_verificate === wx_register_status.TOADDNICKNAME || wechat_verificate === wx_register_status.NICKNAMEFAILED">
                <a-spin :spinning="quicklyLoading">
                  <a-form :form="quick_form" :layout="formLayout">
                    <a-row>
                      <a-col :span="16">
                        <a-descriptions style="margin-left: 60px" title="企业信息">
                          <a-descriptions-item label="企业名" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                            <span>{{ corporationInfo.name }}</span>
                          </a-descriptions-item>
                          <a-descriptions-item label="企业代码" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                            <span>{{ corporationInfo.code }}</span>
                          </a-descriptions-item>
                          <a-descriptions-item label="法人微信" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                            <span>{{ corporationInfo.legal_persona_wechat }}</span>
                          </a-descriptions-item>
                          <a-descriptions-item label="法人姓名" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                            <span>{{ corporationInfo.legal_persona_name }}</span>
                          </a-descriptions-item>
                          <a-descriptions-item label="第三方联系电话" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                            <span>{{ corporationInfo.component_phone }}</span>
                          </a-descriptions-item>
                        </a-descriptions>
                        <div v-if="wechat_verificate === wx_register_status.NICKNAMEFAILED" style="margin-left: 60px;margin-top: 20px;margin-bottom: 20px;color: #8b8b8b">
                          <span><span style="font-size: 16px;color: black;font-weight: bold">提示：</span>小程序名称设置失败，请重新上传</span>
                        </div>
                        <a-form-item label="小程序名称" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" style="margin-top: 20px">
                          <a-input v-decorator="['nickname', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
                        </a-form-item>
                        <a-form-item label="小程序简介" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" style="margin-top: 20px">
                          <a-input v-decorator="['signature', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
                        </a-form-item>
                        <a-form-item label="营业执照" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                          <div class="clearfix">
                            <a-upload
                              v-decorator="['file',{rules: [{required: true, message: '必填项，请填写信息'}]}]"
                              name="file"
                              accept="image/png, image/jpeg, image/jpg"
                              list-type="picture-card"
                              :file-list="fileList"
                              :action="actionUrl"
                              :data="new_multipart_params"
                              :before-upload="beforeUpload"
                              @preview="handlePreview"
                              @change="handleChangePic"
                            >
                              <div v-if="fileList.length < 1">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">
                                  上传
                                </div>
                              </div>
                            </a-upload>
                            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                              <img alt="图片错误" style="width: 100%" :src="previewImage || ''" />
                            </a-modal>
                          </div>
                        </a-form-item>
                        <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" style="margin-left: 60px">
                          <a-button type="primary" @click="quickSetApplet">提交</a-button>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                </a-spin>
              </div>
              <div v-if="quickly_current===0">
                <a-spin :spinning="quicklyLoading">
                  <a-form :form="form" :layout="formLayout">
                    <a-row v-if="wechat_verificate === wx_register_status.UNREGISTERED || wechat_verificate === wx_register_status.REGISTERFAILED">
                      <a-col :span="16">
                        <a-form-item label="上传状态" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" v-if="wechat_verificate === 3">
                          <span style="color: red">上传资料失败，请重新上传</span>
                        </a-form-item>
                        <a-form-item label="企业名" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                          <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
                        </a-form-item>
                        <a-form-item label="代码类型" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                          <a-select @change="handleChange" v-decorator="['code_type', {rules: [{ required: true, message: '请选择' }]}]">
                            <a-select-option value="1">
                              统一社会信用代码
                            </a-select-option>
                            <a-select-option value="2">
                              组织机构代码
                            </a-select-option>
                            <a-select-option value="3">
                              营业执照注册号
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                        <a-form-item label="企业代码" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol"><a-input v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" /></a-form-item>
                        <a-form-item label="法人微信" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol"><a-input v-decorator="['legal_persona_wechat', {rules: [{required: true, message: '必填项，请填写信息'}]}]" /></a-form-item>
                        <a-form-item label="法人姓名" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                          <a-input v-decorator="['legal_persona_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
                        </a-form-item>
                        <a-form-item label="第三方联系电话" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                          <a-input v-decorator="['component_phone', {rules: [{required: true, message: '必填项，请填写信息'}]}]" /></a-form-item>
                        <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                          <a-button style="margin-left: 50px" type="primary" @click="quickRegister">快速注册</a-button>
                        </a-form-item>
                      </a-col>
                    </a-row>
                    <a-row v-if="wechat_verificate === wx_register_status.WAITINGLEGALPERSON">
                      <a-col :span="16">
                        <a-form-item><span style="font-size: 16px">企业基本信息已提交，待微信法人验证</span></a-form-item>
                        <a-form-item>
                          <a-button type="primary" @click="wechatVerificated">已验证</a-button>
                          <p style="margin-left: 0px;color: #8b8b8b ">若微信法人在微信端已验证通过，点击“已验证”按钮</p>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                </a-spin>
              </div>
            </div>
          </div>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="复用公众号主体注册小程序" v-if="false">
        <div style="padding: 10px">
          <a-steps :current="current">
            <a-step v-for="item in steps" :key="item.title" :title="item.title" />
          </a-steps>
          <div class="steps-content" style="margin-top: 10px;padding: 10px">
            <div v-if="current===1">
              <a-spin :spinning="quicklyLoading">
                <a-form :form="quick_form" :layout="formLayout">
                  <a-row>
                    <a-col :span="16">
                      <a-form-item label="小程序名称" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                        <a-input v-decorator="['nickname', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
                      </a-form-item>
                      <a-form-item label="营业执照" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                        <div class="clearfix">
                          <a-upload
                            v-decorator="['file',{rules: [{required: true, message: '必填项，请填写信息'}]}]"
                            name="file"
                            accept="image/png, image/jpeg, image/jpg"
                            list-type="picture-card"
                            :file-list="fileList"
                            :before-upload="beforeUpload"
                            @preview="handlePreview"
                            @change="handleChangePic"
                          >
                            <div v-if="fileList.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">
                                上传
                              </div>
                            </div>
                          </a-upload>
                        </div>
                      </a-form-item>
                      <a-form-item>
                        <a-button type="primary" @click="quickSetApplet">提交</a-button>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </a-spin>
            </div>
            <div v-if="current===0">
              <a-form :form="registerAppletForm" :layout="formLayout">
                <a-row>
                  <a-col :span="16">
                    <a-form-item label="公众号appid" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                      <a-input v-decorator="['appid', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
                      <a :href="findappIdUrl" target="_blank">如何获取公众号appid</a>
                    </a-form-item>
                    <a-form-item label="第三方平台的appid" v-show="false" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                      <a-input v-decorator="['component_appid', {initialValue: 'wxef96f1ad2436f7c5'}]" disabled/>
                    </a-form-item>
                    <a-form-item label="回调地址" v-if="false">
                      <a-input v-decorator="['redirect_uri']"/>
                    </a-form-item>
                    <a-form-item>
                      <a-button type="primary" @click="handleConfigOk" style="margin-top: 0px">提交数据</a-button>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <div v-if="false">
              <a-button type="primary" html-type="submit">
                <a :href="auth_page_href">去授权</a>
              </a-button>
            </div></div>
          <div class="steps-action" style="margin-top: 40px">
            <a-button v-if="false" style="margin-left: 0px" type="primary" @click="prev">
              <a-icon type="left" />
              前一步
            </a-button>
            <!--            current < steps.length - 1-->
            <a-button v-if="false" style="margin-left: 10px" type="primary" @click="next">
              下一步
              <a-icon type="right" />
            </a-button>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="图片错误" style="width: 100%" :src="previewImage || ''" />
    </a-modal>
    <a-modal :visible="okvisible" @cancel="handleCancel" @ok="editPushSignature" :closable="false">
      <a-textarea
        v-model="signatureData"
        placeholder="小程序简介"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
    <a-modal :visible="dianshangnamevisible" @cancel="handleCancel" @ok="editDianshangnameSignature" :closable="false">
      <a-input
        v-model="ebp_name"
        placeholder="电商平台名称"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
    <a-modal :visible="dianshangvisible" @cancel="handleCancel" @ok="editDianshangcodeSignature" :closable="false">
      <a-input
        v-model="ebp_code"
        placeholder="电商平台代码"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
  </div>
</template>

<script>
import urlencode from 'urlencode'
import {
  modify_signature,
  // get_pre_auth_code,
  quick_register_applet,
  quick_set_applet, quick_set_applet_icon,
  search_quick_registration_applet, update_haiguan_data
} from '@/api/applet_register'
import { Dictionaries } from '@/common/AllConstant'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 }
}
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'BaseSettings',
  data () {
    return {
      ebp_name: '',
      ebp_code: '',
      dianshangvisible: false,
      dianshangnamevisible: false,
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      active_key: 1,
      currentPath: '',
      findappIdUrl: 'https://vsp.allinpay.com/statichtml/cusweb/html/getAPPID.html',
      actionUrl: '',
      fileList: [],
      fileAvatarList: [],
      corporationInfo: {},
      wechat_verificate: 0,
      appletLoading: false,
      previewVisible: false,
      upload_file: File | Blob,
      avatar_file: File | Blob,
      previewImage: '',
      current: 0,
      quickly_current: 0,
      steps: [
        // {
        //   title: '去授权',
        //   content: '小程序管理员授权给第三方平台'
        // },
        {
          title: '复用公众号',
          content: '复用公众号快速注册小程序'
        },
        {
          title: '小程序信息',
          content: '复用公众号快速注册小程序'
        }
      ],
      quickly_steps: [
        {
          title: '企业基本信息',
          content: '小程序管理员授权给第三方平台'
        },
        {
          title: '小程序信息',
          content: '复用公众号快速注册小程序'
        }
      ],
      current_ticket: '',
      pre_auth_code: '',
      cooperation_code_type: 1,
      formLayout: 'horizontal',
      formItemLayout,
      registerAppletForm: this.$form.createForm(this),
      applet_icon_form: this.$form.createForm(this),
      configVisible: false,
      configLoading: false,
      quicklyLoading: false,
      appletIconLoading: false,
      tabPosition: 'top',
      okvisible: false,
      signatureData: false,
      tip: '',
      userId: '',
      origin_pwd: '',
      new_pwd: '',
      enter_new_pwd: '',
      form: this.$form.createForm(this),
      quick_form: this.$form.createForm(this),
      // cropper
      preview: {},
      mdl: {},
      wx_register_status: 0,
      option: {
        img: '',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 180,
        autoCropHeight: 180,
        fixedBox: true,
        // 开启宽度和高度比例
        fixed: true,
        fixedNumber: [1, 1]
      }
    }
  },
  created () {
    this.currentPath = window.location.href
    this.wx_register_status = this.$Dictionaries.wx_register_status
  },
  methods: {
    beforeUpload (file) {
      this.new_multipart_params.key = Math.floor(Math.random() * 65535).toString(32) + file.name
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
        return false
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('图片过大，请重新选择上传')
        return false
      }
      // this.handlePreview(file)
      return false
      // return isJpgOrPng && isLt5M
    },
    handleIconUrlPreview (url) {
      this.previewImage = url
      this.previewVisible = true
      console.log(this.previewImage)
    },
    editPushSignature () {
      this.okvisible = false
      const signatureInfo = { signature: this.signatureData }
      modify_signature(signatureInfo).then(res => {
        console.log(res)
      })
    },
    // 进口图片
    async handlePreview (file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChangePic ({ fileList, file }) {
      this.fileList = fileList
      this.upload_file = file.originFileObj || this.fileList[0].originFileObj
      console.log('我是图片111', this.upload_file)
      console.log('我是图片333', file.originFileObj)
      console.log('我是图片222', this.fileList[0].originFileObj)
      // this.form.setFieldsValue({ file: file.originFileObj })
      // if (file.status === 'done') {
      //   this.form.setFieldsValue({ img: this.actionUrl + '/' + this.new_multipart_params.key })
      // }
    },
    handleAvatarChangePic ({ fileList, file }) {
      this.fileAvatarList = fileList
      this.avatar_file = file.originFileObj || this.fileAvatarList[0].originFileObj
      console.log('我是图片111', this.avatar_file)
      // this.form.setFieldsValue({ file: file.originFileObj })
      // if (file.status === 'done') {
      //   this.form.setFieldsValue({ img: this.actionUrl + '/' + this.new_multipart_params.key })
      // }
    },
    wechatPay () {
    this.$router.push('/authorization_event_reception/wechat/wx_pay_apply')
    },
    editDianshangcodeSignature () {
      update_haiguan_data({ ebp_code: this.ebp_code }).then((res) => {
        console.log(res)
        this.previewVisible = false
        this.okvisible = false
        this.dianshangnamevisible = false
        this.dianshangvisible = false
        location.reload()
      })
        .finally(e => {
          console.log(e)
          this.previewVisible = false
          this.okvisible = false
          this.dianshangnamevisible = false
          this.dianshangvisible = false
        })
    },
    editDianshangnameSignature () {
      update_haiguan_data({ ebp_name: this.ebp_name }).then((res) => {
        console.log(res)
        this.previewVisible = false
        this.okvisible = false
        this.dianshangnamevisible = false
        this.dianshangvisible = false
        location.reload()
      })
      .finally(e => {
        console.log(e)
        this.previewVisible = false
        this.okvisible = false
        this.dianshangnamevisible = false
        this.dianshangvisible = false
      })
    },
    handleCancel () {
      this.previewVisible = false
      this.okvisible = false
      this.dianshangnamevisible = false
      this.dianshangvisible = false
    },
    next (e) {
      if (this.current === 0) {
        this.handleConfigOk(e)
      }
      this.current++
    },
    prev () {
      this.current--
    },
    editSignature () {
      this.okvisible = true
    },
    upNamedata  () {
     this.dianshangnamevisible = true
    },
    upcodedata  () {
      this.dianshangvisible = true
    },
    wechatVerificated () {
      this.searchQuickRegistrationApplet()
    },
    handleConfigOk (e) {
      e.preventDefault()
      this.registerAppletForm.validateFields((errors, values) => {
        console.log(values)
        this.active_key = 2
        this.configLoading = true
        if (!errors) {
          const url = 'https://mp.weixin.qq.com/cgi-bin/fastregisterauth?appid=' + values.appid + '&component_appid=' + values.component_appid + '&copy_wx_verify=1&redirect_uri=' + urlencode(this.currentPath)
          console.log(url)
          window.location.href = url
          // window.open(url)
        }
        this.configLoading = false
      })
    },
    handleChange (value) {
      this.cooperation_code_type = value
    },
    quickRegister (e) {
      const form = this.form
      e.preventDefault()
      form.validateFields((errors, values) => {
        console.log(errors)
        console.log(values)
        if (!errors) {
          this.quicklyLoading = true
          quick_register_applet(values).then(res => {
            if (res.code === 1000) {
              this.active_key = 1
              this.wechat_verificate = res.status
            }
            this.quicklyLoading = false
          })
        }
      })
    },
    quickNextRegister (e) {
      const form = this.form
      e.preventDefault()
      form.validateFields((errors, values) => {
        console.log(errors)
        console.log(values)
        if (!errors) {
          this.quicklyLoading = true
          quick_register_applet(values).then(res => {
            console.log(res)
            if (res.code === 1000) {
              this.quickly_current = 1
              this.active_key = 1
            }
            this.quicklyLoading = false
          })
        }
      })
    },
    // 设置小程序图标
    quickSetAppletIcon (e) {
      const form = this.applet_icon_form
      this.appletIconLoading = true
      e.preventDefault()
      const that = this
      form.validateFields((errors, values) => {
        if (!errors) {
          const formData = new FormData()
          formData.append('file', that.avatar_file)
          formData.append('corporation_id', Dictionaries.corporation_id)
          quick_set_applet_icon(formData).then(res => {
            console.log(res.code)
            if (res.code === 1000) {
              this.$message.success('设置成功')
              that.searchQuickRegistrationApplet()
            }
          })
        }
        this.appletIconLoading = false
      })
    },
    // 设置小程序名称
    quickSetApplet (e) {
      const form = this.quick_form
      this.quicklyLoading = true
      e.preventDefault()
      const that = this
      form.validateFields((errors, values) => {
        if (!errors) {
          const formData = new FormData()
          formData.append('file', that.upload_file)
          formData.append('nickname', values.nickname)
          formData.append('corporation_id', Dictionaries.corporation_id)
          quick_set_applet(formData).then(res => {
            console.log(res.code)
            if (res.code === 1000) {
              this.$message.success('设置成功，确认中')
              that.searchQuickRegistrationApplet()
            }
          })
          that.signatureData = values.signature
          that.editPushSignature()
        }
        this.quicklyLoading = false
      })
    },
    checkPathTicket () {
      // 复用公众号主体注册小程序，第一步执行成功后回调
      this.current_ticket = this.$route.query.ticket
      const data = this.current_ticket !== undefined && this.active_key === 2
      console.log(data)
      if (this.current_ticket !== undefined && this.active_key === 2) {
        this.current += 1
      }
    },
    searchQuickRegistrationApplet () {
      this.appletLoading = true
      search_quick_registration_applet().then(res => {
        /**
         *
         UNREGISTERED = 1  # 未注册
         WAITINGLEGALPERSON = 2  # 上送资料成功，待法人通过刷脸
         REGISTERFAILED = 3  # 上送资料失败
         REGISTERED = 4  # 上送资料成功，法人已通过
         TOADDNICKNAME = 5  # 需要设置名称
         ADDINGNICKNAME = 6  # 设置昵称中
         NICKNAMEFAILED = 7  # 设置昵称失败
         TOSETAVATOR = 8  # 需要设置头像
         AVATORFAILED = 9  # 设置头像失败
         COMPLETED = 10  # 快速注册完成
         */
        this.appletLoading = false
        if (res.code === 1000) {
          this.wechat_verificate = res.status
          if (res.status === this.$Dictionaries.wx_register_status.UNREGISTERED) {
            this.wechat_verificate = 1
          } else if (res.status === this.$Dictionaries.wx_register_status.WAITINGLEGALPERSON) {
            this.wechat_verificate = 2
          } else if (res.status === this.$Dictionaries.wx_register_status.REGISTERFAILED) {
            this.wechat_verificate = 3
          } else if (res.status === this.$Dictionaries.wx_register_status.TOADDNICKNAME) {
            // 上送资料成功, 返回资料信息,需要设置名称
            this.wechat_verificate = 4
            this.corporationInfo = res.data
          } else if (res.status === this.$Dictionaries.wx_register_status.ADDINGNICKNAME) {
            // 设置昵称中，等待确认
            this.wechat_verificate = 5
          } else if (res.status === this.$Dictionaries.wx_register_status.NICKNAMEFAILED) {
            // 设置昵称失败，上送资料成功, 返回资料信息,需要设置名称
            this.wechat_verificate = 6
          } else if (res.status === this.$Dictionaries.wx_register_status.TOSETAVATOR) {
            // 需要设置头像
            this.wechat_verificate = 7
          } else if (res.status === this.$Dictionaries.wx_register_status.AVATORFAILED) {
            // 设置头像失败
            this.wechat_verificate = 8
          } else if (res.status === this.$Dictionaries.wx_register_status.COMPLETED) {
            // 快速注册完成
            this.wechat_verificate = 9
            this.corporationInfo = res.data
          }
        }
        console.log(res)
      })
    }
  },
  mounted () {
    // get_pre_auth_code().then(res => {
    //   this.pre_auth_code = res.data.pre_auth_code
    // })
    this.checkPathTicket()
    this.searchQuickRegistrationApplet()
  },
  computed: {
    auth_page_href () {
      // 管理端使用
      return 'https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=wxef96f1ad2436f7c5&pre_auth_code=' + this.pre_auth_code + '&redirect_uri=http://customs-test.jnzbtz.com&auth_type=3'
    }
  }
}
</script>

<style lang="less" scoped>

  .avatar-upload-wrapper {
    height: 200px;
    width: 100%;
  }

  .ant-upload-preview {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;

    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      opacity: 0;
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;

      &:hover {
        opacity: 1;
      }

      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }

    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
  }
</style>
