import request from '@/utils/request'

const appletApi = {
  get_pre_auth_code: '/wechat/get_pre_auth_code/',
  quick_register_applet: '/wechat/quick_registration_applet/',
  quick_set_applet: '/wechat/add_nickname/',
  modify_avatar: '/wechat/modify_avatar/',
  search_quick_registration_applet: '/wechat/search_quick_registration_applet/',
  modify_signature: '/wechat/modify_signature/'
}

/**
 * 获取预授权码
 */
export function get_pre_auth_code (parameter) {
  return request({
    url: appletApi.get_pre_auth_code,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 创建小程序
 */
export function quick_register_applet (parameter) {
  return request({
    url: appletApi.quick_register_applet,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 更新海关备案数据
 */
export function update_haiguan_data (parameter) {
  return request({
    url: appletApi.quick_register_applet,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 设置小程序昵称
 */
export function quick_set_applet (parameter) {
  return request({
    url: appletApi.quick_set_applet,
    method: 'post',
    timeout: 60000, // 请求超时时间
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: parameter
  })
}
/**
 * 设置小程序头像
 */
export function quick_set_applet_icon (parameter) {
  return request({
    url: appletApi.modify_avatar,
    method: 'post',
    timeout: 60000, // 请求超时时间
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: parameter
  })
}
/**
 * 查询快速注册的状态
 */
export function search_quick_registration_applet () {
  return request({
    url: appletApi.search_quick_registration_applet,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 修改简介
 */
export function modify_signature (parameter) {
  return request({
    url: appletApi.modify_signature,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
